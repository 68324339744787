import { graphql, Script } from 'gatsby';
import React from 'react';
import Layout from '../../layout';
import {
  HeadWrapper,
  largestContentfullPaintImg,
} from '../../layout/HeadWrapper';
import ClientsFilter from './ClientsFilter';
import { morphism } from 'morphism';
import { schemaHero } from '../../morphismSchema/templates/schemaHero';
import { Hero } from 'libs/growth-platform-brand-system-v2/src/templates/Hero';
import { TemplateBlocksZone } from '../../components/TemplateBlocksZone';
import { schemaBaseTemplate } from '../../morphismSchema/pages/schemaBaseTemplate';

export const Head = ({ data, pageContext, location }: any) => {
  const heroProps = data?.datoCmsAllClientsPage?.hero?.[0];
  const largestContentfullPaintImg: largestContentfullPaintImg =
    heroProps?.image?.gatsbyImageData?.images?.fallback || null;

  return (
    <HeadWrapper
      data={data}
      pageContext={pageContext}
      location={location}
      templateName="AllClientsPage"
      dataName={'datoCmsAllClientsPage'}
      largestContentfullPaintImg={largestContentfullPaintImg}
    />
  );
};

export const query = graphql`
  query DatoAllClientsPage($locale: String, $id: String) {
    datoCmsAllClientsPage(locale: $locale, id: { eq: $id }) {
      ...AllClientsPage
    }
    allDatoCmsClientPage(locale: $locale, sort: { position: ASC }) {
      nodes {
        ...ClientPage
      }
    }
  }
`;

const AllClientsPage = ({ data, pageContext }: any) => {
  const { datoCmsAllClientsPage, allDatoCmsClientPage } = data;
  const hero = datoCmsAllClientsPage?.hero?.[0];
  const useTrustpilot = hero?.trustpilotWidget?.length > 0;

  const _data = morphism(schemaBaseTemplate, {
    ...datoCmsAllClientsPage,
    pageContext,
  });

  if (!datoCmsAllClientsPage) return null;

  return (
    <Layout
      pageContext={pageContext}
      data={datoCmsAllClientsPage}
      templateName="AllClientsPage"
    >
      {useTrustpilot && (
        <Script src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" />
      )}
      <Hero {...(morphism(schemaHero, hero) as any)} />
      <ClientsFilter
        datoClients={allDatoCmsClientPage?.nodes}
        sectorFilter={datoCmsAllClientsPage.sectorFilter}
        sectorFilterLabel={datoCmsAllClientsPage.sectorFilterLabel}
        sizeFilter={datoCmsAllClientsPage.sizeFilter}
        sizeFilterLabel={datoCmsAllClientsPage.sizeFilterLabel}
        seeMoreButtonLabel={datoCmsAllClientsPage.seeMoreButtonLabel}
        noResultText={datoCmsAllClientsPage.noResultText}
        locale={pageContext.locale}
      />
      {_data?.blocks.length > 0 && <TemplateBlocksZone {..._data} />}
    </Layout>
  );
};

export default AllClientsPage;
